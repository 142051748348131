<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div
      class="container grid gap-20"
      :class="[
        section.styles.gridColumns.value,
      ]"
    >
      <div
        v-for="(item, index) in section.data?.value.items"
        :key="index"
        class="flex gap-4"
        :class="[
          section.styles.iconPlacement.value,
          {
            '!flex-col': section.data?.value.itemDecoration === 'image',
          },
        ]"
      >
        <div
          v-if="section.data?.value.shownElements?.itemDecoration"
          class="flex"
          :class="[
            section.styles.textAlignment.value,
          ]"
        >
          <div
            v-if="section.data?.value.itemDecoration === 'image'"
            class="overflow-hidden bg-(fg-muted)"
            :class="[
              section.styles.imageSize.value,
              section.styles.imageCrop.value,
              section.styles.cornerRadius.value,
            ]"
          >
            <img
              v-if="item.image"
              :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-700,c-at_max/' : ''}${item.image}`"
              class="h-full object-cover"
              :alt="sanitize(item[section.lang.value]?.title)"
            >
          </div>
          <div
            v-if="section.data?.value.itemDecoration === 'icon'"
          >
            <Icon
              :name="item.icon"
              class="text-(fg-subtle) w-8 h-8"
            />
          </div>
        </div>
        <div
          class="w-full"
          :class="[
            section.styles.textAlignment.value,
          ]"
        >
          <h2
            v-if="section.data?.value.shownElements?.itemTitle"
            class="text-lg mb-2"
            v-html="sanitize(item[section.lang.value]?.title)"
          />
          <div
            v-if="section.data?.value.shownElements?.itemDescription"
            class="text-lg"
            v-html="sanitize(item[section.lang.value]?.description)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);
</script>
